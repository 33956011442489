<template>
    <div class="flex flex-column mb-4">
        <Button
            :class="selecionada ? 'bg-primary' : 'bg-gray-500'"
            :label="`Seção ${numero}`"
            class="border-none border-noround border-round-top-md w-max py-2"
            @click.stop="emit('selecionar')"
        />
        <div
            :class="{ 'cursor-pointer': !editavel }"
            class="bg-white flex justify-content-between align-items-center"
            @click.stop="emit('selecionar')"
        >
            <div class="flex flex-column p-4 gap-2">
                <InputText
                    ref="titulo"
                    v-model="secao.titulo"
                    v-show="editavel"
                    :class="{ 'p-invalid': v$.titulo.$error }"
                    class="border-none border-bottom-1 border-noround shadow-none text-2xl font-semibold w-max -mt-2"
                    placeholder="Título da seção"
                />
                <div
                    v-show="!editavel"
                    class="text-2xl font-semibold text-900 mt-2 cursor-auto"
                    style="width: fit-content"
                    @click.stop="emit('selecionar', $refs.titulo.$el)"
                >
                    {{ secao.titulo || 'Título da seção' }}
                </div>
                <small v-if="v$.titulo.$error" class="p-error w-full">{{ v$.titulo.$errors[0].$message }}</small>
                <InputText
                    ref="descricao"
                    v-show="editavel"
                    v-model="secao.descricao"
                    class="border-none border-bottom-1 border-noround shadow-none font-medium w-max"
                    placeholder="Descrição"
                />
                <div
                    v-show="!editavel"
                    class="font-medium text-700 my-3 cursor-auto"
                    style="width: fit-content"
                    @click.stop="emit('selecionar', $refs.descricao.$el)"
                >
                    {{ secao.descricao }}
                </div>
                <small v-if="v$.formularioPergunta.$error" class="p-error w-full">{{ v$.formularioPergunta.$errors[0].$message }}</small>
            </div>
            <Button :disabled="!editavel" class="shadow-none text-600 h-3rem" icon="pi pi-ellipsis-v" size="large" text @click="abrirMenu($event)" />
        </div>
        <slot />
        <div v-if="selecionada" class="mt-4">
            <Button class="px-4" label="Nova seção" @click.stop="emit('adicionar')" />
            <Button class="px-4 ml-4" label="Nova pergunta" @click.stop="emit('adicionar-pergunta')" />
        </div>
        <Menu ref="menu" :model="menuAcoes" :popup="true" />
    </div>
</template>
<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import { ref, defineProps, defineEmits, watch } from 'vue';

const emit = defineEmits(['update:modelValue', 'adicionar', 'adicionar-pergunta', 'excluir', 'duplicar', 'selecionar']);
const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    editavel: {
        type: Boolean,
        default: false
    },
    numero: {
        type: Number,
        required: true
    },
    salvando: {
        type: Boolean,
        default: false
    },
    selecionada: {
        type: Boolean,
        default: false
    }
});

const menu = ref(null);
const menuAcoes = ref([
    {
        label: 'Duplicar seção',
        command: () => emit('duplicar')
    },
    {
        label: 'Exluir seção',
        command: () => emit('excluir')
    }
]);
const secao = ref({ ...props.modelValue });
const validacoes = {
    titulo: {
        required: helpers.withMessage(
            'Este campo é obrigatório',
            requiredIf(() => props.editavel || props.salvando)
        )
    },
    formularioPergunta: {
        required: helpers.withMessage(
            'Adicione ao menos uma pergunta',
            requiredIf(() => props.salvando)
        )
    }
};

const v$ = useVuelidate(validacoes, secao);

watch(secao, (secao) => emit('update:modelValue', secao), { deep: true });
watch(
    () => props.modelValue,
    (valor) => {
        if (JSON.stringify(valor) !== JSON.stringify(secao.value)) {
            secao.value = valor;
        }
    }
);

function abrirMenu(event) {
    menu.value.toggle(event);
}
</script>
