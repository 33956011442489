<template>
    <div>
        <span class="p-input-icon-right cursor-pointer w-full" @click="abrirMenu">
            <i class="pi pi-chevron-down mx-1" />
            <InputText :modelValue="text" :class="inputClass" class="cursor-pointer w-full" placeholder="Selecionar" type="text" />
        </span>
        <Menu
            ref="menu"
            :model="items"
            :popup="true"
            :pt="{
                menu: { class: 'p-2' },
                submenuHeader: { class: 'p-0' }
            }"
        >
            <template #submenuheader="{ item }">
                <div
                    v-if="!item.secaoAtual"
                    :class="{ 'bg-gray-100': item[itemValue] === valor.item?.[itemValue] }"
                    class="font-medium text-700 text-lg cursor-pointer py-2 px-4 my-1 white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    @click="selecionarSecao(item)"
                >
                    <i v-if="item[itemValue] === valor.item?.[itemValue]" class="pi pi-check mr-1" />
                    {{ item[itemText] }}
                </div>
                <div />
            </template>
            <template #item="{ item }">
                <div
                    v-if="verificarSeExibePergunta(item)"
                    :class="{ 'bg-gray-100': item[itemValue] === valor.subItem?.[itemValue] }"
                    class="font-medium text-700 text-lg cursor-pointer py-2 px-4 white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    @click="selecionarPergunta(item)"
                >
                    {{ item[itemText] }}
                </div>
            </template>
            <template #start>
                <div v-if="!items.length" class="text-center text-500 font-medium mt-4">Não há opções diponíveis</div>
            </template>
        </Menu>
    </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, computed, onMounted } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: {
        validate: (value) => !value || typeof value === 'object',
        required: true
    },
    inputClass: {
        type: String || Object,
        default: ''
    },
    items: {
        type: Array,
        default: () => []
    },
    itemText: {
        type: String,
        default: 'text'
    },
    itemValue: {
        type: String,
        required: false
    }
});

const menu = ref(null);
const valor = ref({
    item: {},
    subItem: {}
});

const text = computed(() => {
    return valor.value.subItem?.[props.itemText] || valor.value.item?.[props.itemText];
});

onMounted(() => {
    const item = props.items.find((item) => {
        if (props.modelValue?.tipoRedirecionamento === 'SECAO') {
            return item[props.itemValue] === props.modelValue.destinoId;
        }
        if (props.modelValue?.tipoRedirecionamento === 'PERGUNTA') {
            return item.items.some((subItem) => {
                return subItem[props.itemValue] === props.modelValue.destinoId;
            });
        }
    });

    const subItem = item?.items.find((item) => {
        return item[props.itemValue] === props.modelValue.destinoId;
    });

    valor.value = { item, subItem };
});

function abrirMenu(event) {
    menu.value.toggle(event);
}
function selecionarSecao(secao) {
    const secaoSelecionada = valor.value.item?.id;
    const mesmoItem = !!secaoSelecionada && secao.id === secaoSelecionada;
    valor.value.item = mesmoItem ? {} : secao;
    valor.value.subItem = {};

    const model = {
        tipoRedirecionamento: 'SECAO',
        destinoId: secao.id
    };
    emit('update:modelValue', mesmoItem ? null : model);
}
function selecionarPergunta(pergunta) {
    const perguntaSelecionada = valor.value.subItem?.id;
    const mesmaPergunta = perguntaSelecionada && pergunta.id === perguntaSelecionada;
    valor.value.subItem = mesmaPergunta ? {} : pergunta;

    const secao = props.items?.find((item) => {
        return item.items.some((subItem) => {
            return subItem.id === pergunta.id;
        });
    });

    const model = {
        tipoRedirecionamento: mesmaPergunta ? 'SECAO' : 'PERGUNTA',
        destinoId: mesmaPergunta ? secao.id : pergunta.id
    };
    emit('update:modelValue', mesmaPergunta && !valor.value.item?.id ? null : model);
}
function verificarSeExibePergunta(pergunta) {
    const secaoDaPergunta = props.items?.find((item) => {
        return item.items.some((subItem) => {
            return subItem.id === pergunta.id;
        });
    });
    const secaoSelecionada = valor.value.item?.id;
    const secaoDaPerguntaFoiSelecionada = secaoSelecionada && secaoSelecionada === secaoDaPergunta.id;

    return secaoDaPerguntaFoiSelecionada || secaoDaPergunta.secaoAtual;
}
</script>
