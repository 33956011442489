<template>
    <div v-if="!formularios.length" class="flex flex-column h-full my-auto justify-content-center align-items-center">
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 6rem" />
        <div v-else class="flex flex-column align-items-center">
            <img src="layout/images/formularios/listagem-vazia.svg" alt="Imagem representando listagem vazia" width="132" />
            Ainda não foi adicionada nehuma informação
        </div>
    </div>
    <DataTable
        v-else
        :pt="{
            wrapper: { style: { height: 'calc(100% - 40px)' } }
        }"
        :rows="10"
        :totalRecords="paginacao.totalItems"
        :value="formularios"
        class="h-full"
        lazy
        paginator
        scroll-height="100%"
        scrollable
        @page="listar"
    >
        <Column
            v-for="coluna in colunas"
            :field="coluna.field"
            :header="coluna.header"
            :key="coluna.field"
            :sortable="coluna.sortable"
            :style="{ width: coluna.width }"
            header-class="bg-gray-200"
        >
            <template #body="{ data }">
                <MultiSelect
                    v-if="coluna.type === 'dropdown'"
                    :modelValue="data[coluna.field]"
                    :options="clientes"
                    class="w-full"
                    filter
                    option-label="name"
                    option-value="id"
                    placeholder="Clientes"
                    style="max-width: 300px"
                    @filter="filtarClientes"
                    @update:modelValue="adicionarCliente($event, data)"
                >
                    <template #option="{ option }">
                        {{ option?.name + ' | CodSoc - ' + option?.code }}
                    </template>
                    <template #value="{ value }">
                        <div v-if="!value?.length">Todos</div>
                    </template>
                </MultiSelect>
                <span v-else>
                    {{ coluna.field.split('.').reduce((valor, chave) => valor[chave], data) }}
                </span>
            </template>
        </Column>
        <Column bodyClass="text-right" headerClass="bg-gray-200">
            <template #body="{ data }">
                <Button class="text-600" icon="pi pi-ellipsis-v" text @click="abrirMenu($event, data?.id)" />
            </template>
        </Column>
        <ProgressBar v-if="loading" class="w-full border-noround z-2 absolute" mode="indeterminate" style="height: 4px; top: 40px" />
    </DataTable>
    <Menu ref="menu" :model="menuAcoes" :popup="true" />
    <ConfirmDialog />
</template>
<script setup>
import { onMounted, ref, defineEmits } from 'vue';
import { checkPermission } from '@/common/check-permission';
import { getClientBase } from '@/services/http';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import BaseService from '@/services/BaseService';
import dayjs from 'dayjs';

const emit = defineEmits(['duplicar', 'editar']);

const clientes = ref([]);
const colunas = [
    {
        field: 'titulo',
        header: 'Nome',
        sortable: true,
        width: '60%'
    },
    {
        field: 'tipoFormulario.name',
        header: 'Tipo',
        sortable: true,
        width: '8%'
    },
    {
        field: 'dataCriacao',
        header: 'Data criação',
        sortable: true,
        width: '10%'
    },
    {
        field: 'formulariosClientes',
        header: 'Aplicar para',
        type: 'dropdown',
        width: '20%'
    }
];
const filtragem = ref(null);
const formularios = ref([]);
const formularioId = ref(null);
const loading = ref(false);
const menu = ref(null);
const menuAcoes = ref([
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        disabled: () => !checkPermission('gestaomedica_painel_formularios:editar'),
        command: editar
    },
    {
        label: 'Excluir',
        icon: 'pi pi-trash',
        disabled: () => !checkPermission('gestaomedica_painel_formularios:excluir'),
        command: abrirConfirmacaoExcluir
    },
    {
        label: 'Duplicar',
        icon: 'pi pi-copy',
        command: duplicar
    }
]);
const paginacao = ref({
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0
});
const service = new BaseService('formulario');
const serviceClientes = new BaseService('customers');
const toast = useToast();
const confirm = useConfirm();

onMounted(() => {
    listar();
    buscarClientes();
});

async function adicionarCliente(clientesIds, formulario) {
    try {
        const clienteRemovido = formulario.formulariosClientes.find((cliente) => {
            return !clientesIds.includes(cliente);
        });
        const clienteAdicionado = clientesIds.find((cliente) => {
            return !formulario.formulariosClientes.includes(cliente);
        });

        await getClientBase().post('formulario/formulario-cliente', {
            clienteId: clienteAdicionado || clienteRemovido,
            formularioId: formulario.id,
            insert: !!clienteAdicionado
        });
        listar();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar adicinar cliente ao formulário',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
}
async function buscar() {
    loading.value = true;
    try {
        const { data } = await service.findById(formularioId.value);
        formularioId.value = null;

        return data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar o formulário',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}
async function buscarClientes(event) {
    try {
        const { data } = await serviceClientes.findAll({
            page: event?.page + 1 || 1,
            limit: event?.rows || 10,
            filter: event?.value
        });

        clientes.value = data.items;
    } catch (error) {
        console.log(error);
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar os clientes',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
}
async function duplicar() {
    const formulario = await buscar();
    formulario.id = undefined;
    formulario.secoes.forEach((secao) => {
        secao.id = undefined;
        secao.formularioPergunta.forEach((pergunta) => {
            pergunta.id = undefined;
            pergunta.formularioInstrucaoResposta.forEach((instrucao) => {
                instrucao.id = undefined;
            });
            pergunta.formularioOpcaoResposta.forEach((opcao) => {
                opcao.id = undefined;
                if (opcao.formularioRedirecionamento) {
                    opcao.formularioRedirecionamento.id = undefined;
                }
            });
            if (pergunta.formularioRedirecionamento) {
                pergunta.formularioRedirecionamento.id = undefined;
            }
        });
    });

    emit('duplicar', formulario);
}
async function editar() {
    const formulario = await buscar();
    emit('editar', formulario);
}
async function excluir() {
    loading.value = true;
    try {
        await service.remove(formularioId.value);
        listar();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar o formulário',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}
async function listar(event) {
    loading.value = true;
    try {
        const { data } = await service.findAll({
            page: event?.page + 1 || 1,
            limit: event?.rows || 10
        });
        formularios.value = data.items.map((item) => ({
            ...item,
            formulariosClientes: item.formulariosClientes.map((cliente) => cliente.clienteId),
            dataCriacao: dayjs(item.createdAt).format('DD/MM/YYYY')
        }));
        paginacao.value = data.meta;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar listar os formulários',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}
function abrirMenu(event, id) {
    formularioId.value = id;
    menu.value.toggle(event);
}
function abrirConfirmacaoExcluir() {
    confirm.require({
        message: `O formulário será excluído, deseja continuar?`,
        header: 'Excluir formulário',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Continuar',
        rejectClass: 'p-button-primary p-button-text',
        acceptClass: 'p-button-danger p-button-outlined',
        accept: excluir
    });
}
function filtarClientes(event) {
    if (filtragem.value) {
        clearTimeout(filtragem.value);
    }

    filtragem.value = setTimeout(() => {
        buscarClientes(event);
    }, 1000);
}
</script>
